body, html {
    height: 100%;
    margin: 0;
}

body {
    font-family: "Montserrat", sans-serif;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}
/* font colors */
.whiteFont{
    color:white;
}
.purpleFont{
    color:#3C1552;
}

/* background colors */
.purpleBg {
    background-color:#3C1552;
}
.lightPurplebg{
    background-color: #EAE6EC;
}

/* headings */
h2 {
    font-size: 2rem;
    text-decoration: underline;
    font-weight: normal;
    text-align: center;
}

/* cursor pointer */
.pointer:hover {
    cursor:grab/pointer;
}

/* Navbar */
.desktopNavbarWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 5px;
    padding-left: 20px;
    padding-right: 50px;
}
.desktopNavLinkWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.NavLi {
    text-decoration: none !important;
}
.desktopLogoLi {
    list-style-type: none;
}
.desktopLogoLarge {
    height: 40px;
    width: auto;
}
.desktopNavLink {
    list-style-type: none;
}
.desktopNavLink:hover {
    text-decoration: underline;
    text-decoration-color: #3C1552;
}
.nav-item {
    text-align: center;
}
.desktopDonateBtn {
    text-decoration: none ;
    color: white;
    border-style: none;
    background-color: #3C1552;
    padding: 10px 30px 10px 30px;
    border-radius: 50px;
    transition: transform 0.3s ease;
}
.desktopDonateBtn:hover {
    transform: scale(1.2);
}
.mobileNavItems {
    display: flex;
    justify-content: center;
}
.nav-link {
    color:#3C1552;
}
.mobileDonateBtn {
    border-style: none;
    background-color: #3C1552;
    padding: 10px 30px 10px 30px;
    border-radius: 50px;
}
.mobileDonateBtnText {
    color: white;
}
.navbar-nav {
    gap: 20px;
}
.mobileLogoSmall {
    height: 45px;
    width:auto
}

/* Home/Landing Section */
.landingImg {
    background-image: url("/images/BE365LandingDesktop.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
}
.landingHeading {
    padding-left: 40px;
}
.landingContent {
    display: flex;
    align-items: center;
}
.landingHeading {
    font-style: italic;
    font-weight: 600;
    text-shadow: 6.46px 5.54px 5.63px rgba(176, 160, 185, 0.48);
}
.landingH1 {
    margin-bottom: -3px;
}

.landingSectionMobile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    align-items: center; 
    width: 100%;
    height: 100vh; 
    padding: 20px;
    box-sizing: border-box;
    background-image: url("/images/BE365LandingMobile.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.landingContentMobile {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center; 
    text-align: center;
    max-width: 800px; 
    width: 100%;
}
.landingImgMobile {
    width: 100%;
    height: auto;
}
.landingHeadingMobile{
    text-align: center;
    font-style: italic;
    font-weight: 600;
}
.landingH1Mobile {
    font-size: 2.5rem;
}
.landingPMobile {
    font-size:1.5rem; 
}

/* About Section: What We Do */
.aboutWrapper {
    display: flex; 
    align-items: center;
    width: 100%; 
}
.aboutWrapperItem {
    box-sizing: border-box;
}
.aboutItem1 {
    flex: 0 0 30%;
}
.aboutItem2 {
    flex: 0 0 40%;
}
.aboutItem3 {
    flex: 0 0 30%;
}
.aboutImg{
    width: 20rem;
}
.rightAboutImg {
    float: right;
}
.leftAboutImg {
    float: left;
}
.aboutHeading {
    padding-top: 10px;
}
.aboutInfo {
    font-size: .9rem;
}
.readMoreBtn {
    font-size: .9rem;
    font-weight: 500;
}


/* Get Involved Section */
.getInvolvedWrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 200px;
}
.getInvolvedH2 {
    padding-top: 20px;
    margin-bottom: -10px;
}
.getInvolvedIcons {
    transition: all .2s ease-in-out; 
}
.getInvolvedIcons:hover {
    transform: scale(1.1);
}

/* News Section */
.newsSectionHeader {
    margin-top: -150px;
 
}
.newsWrapper {
    display:flex;
    align-items: center;
    justify-content: center;
}
.winnerImg {
    width: 100%;
}
.newsParagraph {
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
}
.newsWrapperItem {
    box-sizing: border-box;
}
.newsItem1 {
    flex: 0 0 50%;
}
.newsItem2 {
    flex: 0 0 50%;
}

/* Nomination Buttons */
.nominateBtnWrapper {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.nominateBtn {
    padding: 20px 40px 20px 40px;    
    border-radius: 20px;
    border-style: none;
    transition: all .2s ease-in-out; 
}
.nominateBtn:hover {
    transform: scale(1.1);
}

/* Wishlist Section */
.wishlistSection {
    padding-top: 30px;
    padding-bottom: 30px;
}
.wishlistQuote {
    padding-top: 10px;
    padding-bottom: 20px;
}
.wishlistWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}
.wishListbtn {
    padding:10px 20px;
    color: #3C1552;
    background-color: #EAE6EC;
    border-color: #3C1552;
    border-width: 1px;
    border-radius: 30px;
}
.card-body {
    color: #3C1552;
}

/* Contact and FAQ Section*/
.contactFaqWrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    
}
.contactFaqHalf {
    width: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
}
.faqHalf {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
}
.accordion {
    padding-top: 15px;
}
.contactForm {
    padding-top: 30px;
    padding-bottom: 30px;
}
.contactFormbtn {
    background-color: #EAE6EC;
    color: #3C1552;
    padding: 10px 40px;
    border-style: none;
    border-radius: 30px;
    margin-top: 25px;
}

/* Footer Section */
.footerSection {
    padding-top: 20px;
    padding-bottom: 20px;
}
.footerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.footerIconsWrapper {
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.footerLogo {
    width: 8rem;
}
.footerEmail {
    text-decoration: none;
    color:#3C1552;
}
.footerIcons {
    width: 3.5rem;
}
.footerEmailPara {
    text-align: center;
}
.mobileFooterWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobileFooterItem {
    width: 100%;
}
.mobileFooterIconsWrapper {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 15px;
}
.mobileFooterEmailDiv {
    padding-top: 20px;
    padding-bottom: 20px;
}
.bottom-border {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #3C1552;
}
.mobileFooterIcons {
    width: 4rem;
}
.mobileFooterLogo {
    display: flex;
    justify-content: center;
}
.copyrightDiv {
    display: flex;
}
.copyright {
    padding-bottom: 10px;
    color: rgba(60, 21, 82, 0.71);
    text-decoration: none;
    font-size: .8rem;
    text-align: center;
}

/* Email success page */
.emailsSuccessPage {
    height: 100vh;
    display: flex;
    align-items: center;
}
.emailsSuccessDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.emailSuccess {
    text-align: center;
}
.emailSuccessP1 {
    font-size: 2rem;
}
.emailSuccessP2 {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 1.5rem;
}
.emailSuccessReturnBtnDiv {
    width: 200px;
    padding: 10px 30px 10px 30px;
    display: flex;
    justify-content: center;
    border-style: none;
    border-radius: 50px;
    transition: all .2s ease-in-out; 
}
.emailSuccessReturnBtnDiv:hover {
    transform: scale(1.1);
}

.emailSuccessBtn {
    font-size: 1rem;
    text-align: center;
    color:#EAE6EC;
    text-decoration: none;
}

/* Donation collection page */
.donationPageSection {
    display: flex;
    flex-direction: column;
    min-height: 100vh;  
}
.donationContentWrap {
    flex: 1;  
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.donationContent {
    text-align: center;
}
.purpleFont {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.goBackBtnDiv {
    padding-top: 20px;
}
.goBackBtn {
    text-decoration: none;
    color: white;
    background-color: #3C1552;
    padding: 12px 40px 12px 40px;
    border-style: none;
    border-radius: 100px;
}


/* --- Break Points --- */
@media (min-width: 2881px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
    /* Landing */
    .landingSectionMobile {
        display: none;
    }
    /* About */
    .aboutWrapperMobile {
        display: none;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    /* Contact */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .mobileFooterSection {
        display: none;
    }

}
@media (min-width: 1367px) and (max-width: 2880px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
    .landingSectionMobile {
        display: none;
    }
    /* About */
    .aboutWrapperMobile {
        display: none;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    /* Contact */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .mobileFooterSection {
        display: none;
    }
}

@media (min-width: 1201px) and (max-width: 1366px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
    /* Landing */
    .landingSectionMobile {
        display: none;
    }
    /* About */
    .aboutWrapperMobile {
        display: none;
    }
    /* Get Involved */
    .getInvolvedWrapper {
        padding-top: 10px;
        gap: 200px;
    }
    .getInvolvedIcons {
        width: 150px;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    /* contact and faq */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .mobileFooterSection {
        display: none;
    }
    .footerLogo {
        width: 12rem;
    }
    .footerIcons {
        width: 5rem;
    }
    .copyright {
        text-align: start;
    }
}

@media (min-width: 1151px) and (max-width: 1200px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
    /* Landing */
    .landingSectionMobile {
        display: none;
    }
    .landingH1 {
        font-size: 3.5rem;
    }
    .landingP {
        font-size: 1.8rem;
    }
    /* About */
    .aboutWrapperMobile {
        display: none;
    }
    .aboutImg {
        width: 15rem;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    .newsSectionHeader {
        margin-top: 0px;
    }
    .newsParagraph {
        padding-top: 20px;
    }
    /* contact and faq */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* footer */
    .mobileFooterSection {
        display: none;
    }
    .copyright {
        text-align: start;
    }
}
@media (min-width: 1025px) and (max-width: 1150px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
    /* Landing */
    .landingSectionMobile {
        display: none;
    }
    .landingH1 {
        font-size: 3rem;
    }
    .landingP {
        font-size: 1.5rem;
    }
    /* About */
    .aboutWrapperMobile {
        display: none;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    /* contact and faq */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .mobileFooterSection {
        display: none;
    }
    
}
@media (min-width: 913px) and (max-width: 1024px) {
    /* Navbar */
    .navBarSectionMobile {
        display: none;
    }
   /* Landing */
   .landingSectionMobile {
    display: none;
    }
    @media (min-width: 1023px) and (max-width: 1024px) {
        .landingHeading {
            margin-top: -220px;
        }
    }

    /* About */
    .aboutWrapper {
        display: none;
    }
    .aboutWrapperMobile {
        padding-bottom: 20px;
    }
    .aboutInfo {
        padding: 15px 30px 15px 30px;
    }
    .aboutImgWrapperMobile {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .aboutImgMobile {
        width: 20rem;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    .newsSectionHeader {
        margin-top: -80px;
    }
    .newsParagraph {
        padding-top: 15px;
    }
    /* contact and faq */
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .mobileFooterSection {
        display: none;
    }
} 

@media (min-width: 769px) and (max-width: 912px) {
    /* Navbar */
    .navBarSectionDesktop {
        display: none;
    }
    /* Landing */
    .landingSectionDesktop {
        display: none;
    }
    .landingSectionMobile {
        justify-content: flex-start; 
        padding: 40px; 
    }
    .landingContentMobile {
        margin-top: 10vh; 
    }
    .landingHeadingMobile {
        height: auto; 

    }
    .landingImgMobile {
        max-width: 600px;
    }
    .landingH1Mobile {
        font-size: 4rem;
        margin: 0;
    }
    .landingPMobile {
        font-size: 2rem; 
        margin: 0;
    }

    /* About */
    .aboutWrapper {
        display: none;
    }
    .aboutWrapperMobile {
        padding-bottom: 20px;
    }
    .aboutInfo {
        padding: 15px 30px 15px 30px;
    }
    .aboutImgWrapperMobile {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .aboutImgMobile {
        width: 20rem;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    .newsSectionHeader {
        margin-top: 0px; 
    }
    .newsParagraph {
        padding-top: 15px
    }
    /* contact and faq */
    .contactFaqWrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .contactFaqHalf {
        width: 100%;
    }
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .footerSection {
        display: none;
    }
}  

@media (min-width: 541px) and (max-width: 768px) {
    /* Navbar */
    .navBarSectionDesktop {
        display: none;
    }
    /* Landing */
    .landingSectionDesktop {
        display: none;
    }
    .landingSectionMobile {
        justify-content: flex-start; 
        padding: 30px;
    }
    
    .landingContentMobile {
        margin-top: 10vh; 
    }
    
    .landingHeadingMobile {
        height: auto; 
    }
    
    .landingImgMobile {
        max-width: 100%;
    }
    
    .landingH1Mobile {
        font-size: 3rem;
    }
    
    .landingPMobile {
        font-size: 1.8rem;
    }

    /* About */
    .aboutWrapper {
        display: none;
    }
    .aboutWrapperMobile {
        padding-bottom: 20px;
    }
    .aboutInfo {
        padding: 15px 30px 15px 30px;
    }
    .aboutImgWrapperMobile {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .aboutImgMobile {
        width: 20rem;
    }
    /* contact and faq */
    .contactFaqWrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .contactFaqHalf {
        width: 100%;
    }
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .footerSection {
        display: none;
    }
    .footerIcons {
        width: 3rem !important;
    }

    @media (min-width: 541px) and (max-width: 650px) {
        h2 {
            font-size: 1.7rem;
        }
        /* Landing */
        .landingSectionDesktop {
            display: none;
        }
        .landingSectionMobile {
            justify-content: flex-start; 
            padding: 20px;
        }
        
        .landingContentMobile {
            margin-top: 15vh; 
        }
        
        .landingHeadingMobile {
            height: auto; 
        }
        
        .landingImgMobile {
            max-width: 100%;
        }
        
        .landingH1Mobile {
            font-size: 2.5rem;
        }
        
        .landingPMobile {
            font-size: 1.5rem;
        }

        /* about */
        .aboutHeading {
            padding-top: 20px;
        }
        .aboutWrapperMobile {
            margin-top: 20px;
        }
        .aboutImgMobile {
            width: 15rem;
        }
    }
    /* Get Involved */
    .getInvolvedWrapper {
        padding-top: 10px;
        gap: 80px;
    }
    .getInvolvedIcons {
        width: 120px;
    }
    /* News */
    .newsSectionMobile {
        display: none;
    }
    .newsSection {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .newsSectionHeader {
        margin-top: 0px;
        padding-top: 15px;
        padding-bottom: 20px;
    }
    .newsParagraph {
        padding-top: 0px;
        padding-bottom: 25px;
    }
}

@media (min-width: 481px) and (max-width: 540px) {
    h2 {
        font-size: 1.8rem;
    }
    /* Navbar */
    .navBarSectionDesktop {
        display: none;
    }
    /* Landing */
    .landingSectionDesktop {
        display: none;
    }
    .landingSectionMobile {
        justify-content: flex-start; 
        padding: 20px;
    }
    .landingContentMobile {
        margin-top: 10vh; 
    }
    .landingHeadingMobile {
        height: auto; 
    }
    .landingImgMobile {
        max-width: 100%;
    }
    .landingH1Mobile {
        font-size: 2rem;
    }
    
    .landingPMobile {
        font-size: 1.2rem;
    }
    /* About */
    .aboutWrapper {
        display: none;
    }
    .aboutWrapperMobile {
        padding-bottom: 20px;
    }
    .aboutHeading {
        padding-top: 20px;
    }
    .aboutInfo {
        padding: 15px 30px 15px 30px;
    }
    .aboutImgWrapperMobile {
        display: flex;
        justify-content: center;
        gap: 10px;
    }
    .aboutImgMobile {
        width: 15rem;
    }

    .getInvolvedIcons {
        width: 100px;
    }
    /* News */
    .newsSection {
        display: none;
    }
    .newsSectionHeader {
        margin-top: 0px; 
        padding-top: 20px;
        padding-bottom: 10px
    }
    .winnerImg {
        width: 70%;
    }
    .winnerImgDiv {
        display: flex;
        justify-content: center;
    }

    h2 {
        font-size: 1.8rem;
    }
    .newsParagraph {
        padding-top: 15px;
        padding-bottom: 25px;
    }
    /* Get Involved */
    .getInvolvedWrapper {
        padding-top: 10px;
        gap: 50px;
    }
    /* Contact and FAQ*/
    .contactFaqWrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .contactFaqHalf {
        width: 100%;
    }
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* Footer */
    .footerSection {
        display: none;
    }
    /* Donation page */
    .donationPageHeading {
        font-size: 1.5rem;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    /* Navbar */
    .navBarSectionDesktop {
        display: none;
    }
    /* Landing */
    .landingSectionDesktop {
        display: none;
    }
    .landingHeadingMobile {
        padding-top: 130px;
    }
    .landingH1Mobile {
        font-size: 2.5rem;
    }
    .landingPMobile {
        font-size: 1.5rem;
    }
    /* About */
    .aboutWrapper {
        display: none;
    }
    .aboutImgMobile {
        display: none;
    }
    .aboutHeading {
        padding-top: 20px;
    }
    .aboutInfo {
        padding: 15px 30px 15px 30px;
    }
    /* Get Involved */
    .getInvolvedWrapper {
        gap: 15px;
    }
    .getInvolvedIcons {
        width: 80px;
    }
    /* News */
    .newsSection {
        display: none;
    }
    .newsSectionMobile {
        display: flex
    }
    .newsSectionHeader {
        margin-top: 0px;
        padding-top: 20px;
        padding-bottom: 15px
    }
    .newsParagraph {
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 20px;
    }
    /* nominate buttons */
    .nominateBtnWrapper {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 12px;
    }
    
    /* Contact and FAQ*/
    .contactFaqWrapper {
        display: flex;
        flex-direction: column-reverse;
    }
    .contactFaqHalf {
        width: 100%;
    }
    .contactForm {
        padding-left: 20px;
        padding-right: 20px;
    }
    /* footer */
    .footerSection {
        display: none;
    }

    @media (min-width: 360px) and (max-width: 375px) {
        .landingHeadingMobile {
            padding-top: 100px;
        }
        .landingH1Mobile {
            font-size: 2.5rem;
        }
    }
      /* Donation page */
      .donationPageHeading {
        font-size: 1.5rem;
    }
}

